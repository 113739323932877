
import BudgetsInvoicesService from "@/modules/budgets-invoices/budgets-invoices-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesService.create(payload).then(response => {
                resolve({
                    message: "BudgetsInvoices criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsInvoices",
                    success: false,
                    error
                });
            })
        })
    },
    update(id,payload) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesService.update(id,payload).then(response => {
                resolve({
                    message: "BudgetsInvoices atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsInvoices",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesService.remove(id).then(response => {
                resolve({
                    message: "BudgetsInvoices removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsInvoices",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesService.delete(id).then(response => {
                resolve({
                    message: "BudgetsInvoices deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsInvoices",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesService.get(id).then(response => {
                resolve({
                    message: "BudgetsInvoices encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsInvoices",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsInvoices encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsInvoices",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsInvoicesService.all().then(response => {
                resolve({
                    message: "BudgetsInvoices encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsInvoices",
                    success: false,
                    error
                });
            })
        })
    }
}
