
import BudgetsService from "@/modules/budgets/budgets-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsService.create(payload).then(response => {
                resolve({
                    message: "Budgets criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar Budgets",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BudgetsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "Budgets atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar Budgets",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsService.remove(id).then(response => {
                resolve({
                    message: "Budgets removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover Budgets",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsService.delete(id).then(response => {
                resolve({
                    message: "Budgets deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar Budgets",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsService.get(id).then(response => {
                resolve({
                    message: "Budgets encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Budgets",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsService.paginate(query).then(response => {
                resolve({
                    message: "Budgets encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Budgets",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsService.all().then(response => {
                resolve({
                    message: "Budgets encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Budgets",
                    success: false,
                    error
                });
            })
        })
    }
}
